<template>
  <div class="footer">
    <!-- 便利电科技出品 -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.footer {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.45);
}
</style>
