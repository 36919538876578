<template>
  <el-container>
    <!-- 左侧菜单 -->
    <el-aside class="side_menu_wrap" id="side_menu_wrap_id" width="180px">
      <div class="logo_wrap">
        <img class="logo_img" :src="logo" />
        <div style="text-align: center">辽宁良医互联网医院</div>
      </div>
      <side-menu class="side_menu"></side-menu>
    </el-aside>

    <!-- 右侧 right_content 是滚动容器 -->
    <el-container class="right_content">
      <el-main class="el_main_my">
        <!-- 业务页面 -->
        <div class="roter_view_wrap">
          <el-header class="header_wrap" height="46px">
            <Header />
          </el-header>
          <!-- 判断当前页面权限 -->
          <div class="page_container">
            <PageContainer>
              <router-view v-slot="{ Component }">
                <keep-alive :include="keepAliveComponents">
                  <component :is="Component" />
                </keep-alive>
              </router-view>
            </PageContainer>
          </div>
        </div>
        <Footer />
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import SideMenu from '@/components/layout/SideMenu/index.vue'
import Header from '@/components/layout/Header/index.vue'
import Footer from '@/components/layout/Footer/index.vue'
import PageContainer from '@/components/layout/PageContainer'
import logo from '@/assets/logo_white.png'

const store = useStore()
const keepAliveComponents = computed(() => store.state.global.keepAliveComponents)
</script>

<style lang="less">
@import url('./index_global.less');
</style>

<style lang="less" scoped>
@import url('./index.less');
</style>
